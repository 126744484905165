<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { TemperIcon } from '@temperworks/icons'
import { Typography, TextLink } from '@temperworks/components'
import { TypographyType, ToasterType, ToasterVariant, IconSizes } from '@temperworks/types'

interface Props {
  type: ToasterType
  title: string
  toasterText: string
  open: boolean
  variant?: ToasterVariant
  ctaCopy?: string
  autoHide?: boolean
}
const props = defineProps<Props>()
const emit = defineEmits(['close-toaster', 'clicked'])
const active = ref<boolean>(false)
const icon = ref<string>('checkmark')

function close() {
  emit('close-toaster')
  active.value = false
}

onMounted(() => {
  active.value = props.open
  if (props.type === 'danger') {
    icon.value = 'alertOn'
  } else if (props.type === 'info') {
    icon.value = 'bubbleExclamation'
  }

  if (props.autoHide) {
    const hide = setTimeout(() => {
      close()
      clearTimeout(hide)
    }, 2500)
  }
})
</script>

<template>
  <div
    v-if="active"
    class="toaster"
    :class="[props.type, {'active': active}]"
  >
    <div class="icon-holder">
      <TemperIcon
        :name="icon"
        class="bright"
      />
    </div>
    <div class="toaster-content">
      <Typography
        :content="title"
        :variant="TypographyType.bodyMedium"
        class="mb-1"
      />
      <Typography
        :content="toasterText"
        :variant="TypographyType.bodySmall"
        class="toaster-text"
      />
      <div
        v-if="props.variant && props.ctaCopy"
        class="toaster-cta"
      >
        <TextLink
          :link-text="props.ctaCopy"
          :variant="TypographyType.bodySmall"
          @clicked="emit('clicked')"
        />
      </div>
    </div>
    <div
      class="toaster-close"
      @click="close()"
    >
      <TemperIcon
        name="close"
        :size="IconSizes.standard"
        :class="
          [
            { 'bright': type === 'success' },
            { 'bright': type === 'danger' },
            { 'dark': type === 'info' }
          ]
        "
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use './Toaster.scss'
</style>
